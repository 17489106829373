import React, { createContext, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';

import Axios from 'axios';
import { sentryCaptureException } from '~/errors/sentry';
import { useTranslate } from '~/i18n';

// context provider
export const CurrencyContext = createContext({
   currency: 'SAR',
   setCurrency: (_currency: string) => {},
   currencySymbol: 'SAR',
   setCurrencySymbol: (_currencySymbol: string) => {},
   currencyRate: 1,
   setCurrencyRate: (_currencyRate: number) => {},
   currencyRateLoading: false,
   setCurrencyRateLoading: (_currencyRateLoading: boolean) => {},
   currencyRateError: false,
   setCurrencyRateError: (_currencyRateError: boolean) => {},
   currencyRateErrorText: '',
   setCurrencyRateErrorText: (_currencyRateErrorText: string) => {},
   getConvertedPrice: (_price: number) => {},
   getConvertedPriceWithSymbol: (_price: number) => '',
   currenciesList: [],
   setCurrenciesList: (_currenciesList: any) => {},
});

export const CurrencyProvider = ({ children }: any) => {
   const { lang } = useTranslate();
   const locale = lang.split('-')[1];

   const [currency, setCurrency] = useState('');
   const [currenciesData, setCurrenciesData] = useState([]);
   const [currencySymbol, setCurrencySymbol] = useState('SAR');
   const [currencyRate, setCurrencyRate] = useState(1);
   const [currencyRateLoading, setCurrencyRateLoading] = useState(false);
   const [currencyRateError, setCurrencyRateError] = useState(false);
   const [currencyRateErrorText, setCurrencyRateErrorText] = useState('');
   const [, setCurrencies] = useState<any>([]);
   const [currenciesList, setCurrenciesList] = useState<any>([]);

   const getCurrencyRate = async () => {
      try {
         const { data: response }: any = await Axios.get('/api/currencies');
         const { data } = response;
         if (data?.status?.toLowerCase() === 'success') {
            const currenciesData = data?.data;
            setCurrenciesData(currenciesData);
         } else throw new Error(data?.error || data?.original?.error || 'Something went wrong');
      } catch (error) {
         console.log(error);
         const err = error as Error;
         err.name = 'CurrencyProviderException';
         sentryCaptureException(err);
      }
   };

   useEffect(() => {
      getCurrencyRate();
   }, [locale]);

   useEffect(() => {
      const currencies = currenciesData?.map((item: { slug: string; value: number }) => ({
         [item.slug]: item.value,
      }));

      setCurrencies(currencies);
      if (!getCookie('currency')) {
         setCookie('currency', 'SAR');
      }

      setCurrencies(currencies);

      const currencyCookie = getCookie('currency') || ('' as any);
      setCurrency(currencyCookie);

      currencies?.map((item: any) => {
         if (item[currency]) {
            setCurrencyRate(item[currency]);
         }
      });

      // set currency symbol
      currenciesData?.map((item: any) => {
         if (item.slug === currency) {
            // setCurrencySymbol(item.slug);
            locale === 'ar'
               ? setCurrencySymbol(
                    item.slug === 'SAR'
                       ? item?.title?.ar?.split(' ')[0] ?? 'ريال'
                       : item.slug === 'USD'
                         ? item?.title?.ar?.split(' ')[0] ?? 'دولار'
                         : item.slug
                 )
               : setCurrencySymbol(item.slug);
         }
      });

      // Create Currency List
      const currenciesListArr = currenciesData?.map((item: any) => ({
         value: item.slug,
         label: locale === 'ar' ? item.title.ar : item.title.en,
      }));
      setCurrenciesList(currenciesListArr);

      setCurrencyRateLoading(false);
   }, [currenciesData, currency, locale]);

   const getConvertedPrice = (price: any) => {
      const convertedPrice = price?.toString().replace(/,/g, '') * currencyRate;
      const convertedPriceFormated =
         convertedPrice > 0
            ? convertedPrice
                 .toFixed(2)
                 .replace(/\.00$/, '')
                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0;
      return convertedPriceFormated;
   };

   const getConvertedPriceWithSymbol: any = (price: any) => {
      const convertedPrice = price?.toString().replace(/,/g, '') * currencyRate;
      const convertedPriceFormated =
         convertedPrice > 0
            ? convertedPrice
                 .toFixed(2)
                 .replace(/\.00$/, '')
                 .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : 0;
      return `${convertedPriceFormated} ${currencySymbol}`;
   };

   return (
      <CurrencyContext.Provider
         value={{
            currency,
            setCurrency,
            currencySymbol,
            setCurrencySymbol,
            currencyRate,
            setCurrencyRate,
            currencyRateLoading,
            setCurrencyRateLoading,
            currencyRateError,
            setCurrencyRateError,
            currencyRateErrorText,
            setCurrencyRateErrorText,
            getConvertedPrice,
            getConvertedPriceWithSymbol,
            currenciesList,
            setCurrenciesList,
         }}>
         {children}
      </CurrencyContext.Provider>
   );
};

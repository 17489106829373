type Title = { [key: string]: any };
type Links = {
   external?: boolean;
   title: Title;
   href: string;
   ar_href?: string;
};
type FooterLinks = {
   title: Title;
   links: Links[];
}[];

export const footer_links: FooterLinks = [
   {
      title: {
         en: 'Discover Saudi',
         ar: 'اكتشف السعودية',
         zh: '探索沙特',
      },
      links: [
         {
            href: 'https://rewards.visitsaudi.com/us/?lang=en-AU',
            ar_href: 'https://rewards.visitsaudi.com/us/?lang=ar-SA',
            external: true,
            title: {
               en: 'Saudi Rewards',
               ar: 'مكافآت السعودية',
               zh: '沙特奖励',
            },
         },
         {
            href: 'https://www.visitsaudi.com/:lang/destinations',
            external: true,
            title: {
               en: 'Destinations',
               ar: 'الوجهات السياحية',
               zh: '目的地',
            },
         },
         {
            href: 'https://www.visitsaudi.com/:lang/things-to-do',
            external: true,
            title: {
               en: 'Things To Do',
               ar: 'أنشطة تقوم بها',
               zh: '待办事项',
            },
         },
         {
            href: 'https://www.visitsaudi.com/:lang/plan-your-trip',
            external: true,
            title: {
               en: 'Plan Your Trip',
               ar: 'خطط رحلتك',
               zh: '计划您的旅行',
            },
         },
         {
            href: 'https://www.visitsaudi.com/:lang/calendar',
            external: true,
            title: {
               en: 'Saudi Calendar',
               ar: 'تقويم الفعاليات',
               zh: '沙特日历',
            },
         },
         {
            href: 'https://map.visitsaudi.com/:lang',
            external: true,
            title: {
               en: 'Saudi Map',
               ar: 'الخريطة التفاعلية',
               zh: '沙特地图',
            },
         },
      ],
   },
   {
      title: {
         en: 'Business & Partner',
         ar: 'لشركاء والأعمال',
         zh: '业务与合作伙伴',
      },
      links: [
         {
            href: 'https://partner.visitsaudi.com/:lang/home.html',
            external: true,
            title: {
               en: 'Visit Saudi Partner',
               ar: 'شريك روح السعودية',
               zh: '访问沙特合作伙伴',
            },
         },
         {
            href: 'https://partner.visitsaudi.com/:lang/concierge/unauthorized.html',
            external: true,
            title: {
               en: 'Digital Concierge',
               ar: 'خدمات الاستقبال والإرشاد الرقمي',
               zh: '数字礼宾服务',
            },
         },
         {
            href: 'https://learning.sta.gov.sa/',
            external: true,
            title: {
               en: 'E-Learning Courses',
               ar: 'دورات التعلم الإلكتروني',
               zh: '在线学习课程',
            },
         },
         {
            href: 'https://partner.visitsaudi.com/:lang/stoch/home.html',
            external: true,
            title: {
               en: 'Promotional Assets',
               ar: 'المحتوى السياحي الرقمي',
               zh: '宣传资产',
            },
         },
         {
            href: 'https://marketplace.sta.gov.sa/:lang',
            external: true,
            title: {
               en: 'Packages & Experiences',
               ar: 'التجارب والباقات السياحية',
               zh: '套餐与体验',
            },
         },
         {
            href: 'https://partner.visitsaudi.com/:lang/directory/home.html',
            external: true,
            title: {
               en: 'Business Directory',
               ar: 'دليل الأعمال',
               zh: '商业目录',
            },
         },
      ],
   },
   {
      title: {
         en: 'Get Help',
         ar: 'للمساعدة',
         zh: '获取帮助',
      },
      links: [
         {
            href: 'https://www.visitsaudi.com/:lang/help-center',
            external: true,
            title: {
               en: 'Help Center',
               ar: 'مركز العناية بالزوّار',
               zh: '帮助中心',
            },
         },
         {
            href: 'https://www.visitsaudi.com/:lang/contact-us',
            external: true,
            title: {
               en: 'Contact Us',
               ar: 'اتصل بنا',
               zh: '联系我们',
            },
         },
         {
            href: 'https://api.whatsapp.com/send?phone=966114907617',
            external: true,
            title: {
               en: 'Start a Whatsapp chat',
               ar: 'دردش معنا على واتس اب',
               zh: '开始 WhatsApp 聊天',
            },
         },
      ],
   },
];

export const policy_links: Links[] = [
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/contact-us',
      title: {
         en: 'Contact Us',
         ar: 'اتصل بنا',
         zh: '联系我们',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/privacy-policy/terms-and-conditions',
      title: {
         en: 'Terms and Conditions',
         ar: 'الشروط والأحكام',
         zh: '条款和条件',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/privacy-policy',
      title: {
         en: 'Privacy policy',
         ar: 'سياسة الخصوصية',
         zh: '电子学习',
      },
   },
   {
      external: true,
      href: 'https://www.visitsaudi.com/:lang/cookies',
      title: {
         en: 'Cookies',
         ar: 'سياسة الكوكيز',
         zh: '企业目录',
      },
   },
];
